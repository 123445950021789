<template>
    <div class="main">
        <div class="search">
            <div class="refresh" @click="getPhoneCodeList"><i class="fa fa-refresh"></i></div>
        </div>
        <div class="data">
            <el-table
                    :data="codeList"
                    style="width: 100%"
                    max-height="730"
                    border>
                <el-table-column
                        label="날짜"
                        width="130">
                    <template slot-scope="scope">
                        {{scope.row.createTime|datef('MM-DD HH:mm:ss')}}
                    </template>
                </el-table-column>
                <el-table-column
                        label="IP"
                        width="200">
                    <template slot-scope="scope">
                        {{scope.row.ip}}
                    </template>
                </el-table-column>

                <el-table-column
                        label="휴대폰"
                        width="120">
                    <template slot-scope="scope">
                        {{scope.row.phone}}
                    </template>
                </el-table-column>

                <el-table-column
                        label="인증코드"
                        width="120">
                    <template slot-scope="scope">
                        {{scope.row.code}}
                    </template>
                </el-table-column>


                <el-table-column
                        label="가입상태"
                        width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == managerConst.YES" style="color: deepskyblue;font-weight: bold">완료</span>
                        <span v-if="scope.row.status == managerConst.NOT" style="color: grey">대기</span>
                    </template>
                </el-table-column>



                <el-table-column
                        label="확인상태"
                        width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.checked == managerConst.CHECKED" >확인완료</span>
                        <span v-if="scope.row.checked == managerConst.UNCHECKED" class="text-danger">미확인</span>
                    </template>
                </el-table-column>

<!--                <el-table-column-->
<!--                        label=""-->
<!--                        width="100">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-button size="mini" type="primary" @click="updateChecked(scope.row)">확인</el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->




            </el-table>
        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[20, 50, 100, 150, 200]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>

</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {updateGroup} from "../../network/manager/groupRequest";
    import {manager} from "../../common/administrator/managerMixin";
    import managerConst from "../../common/administrator/managerConst";
    import {getLogCashPointList} from "../../network/manager/logCashPointRequest";
    import {getModifiedHistoryList, updateAllModifiedHistory} from "../../network/manager/logModifyRequest";
    import Vue from "vue";
    import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
    import PartnerEditToast from "../../components/administrator/UserPanel/PartnerEditToast";
    import {getLoginLogList} from "../../network/manager/logLoginRequest";
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import {Loading} from "element-ui";
    import AgentEditToast from "../../components/administrator/UserPanel/AgentEditToast";
    import {getPhoneCodeList, updatePhoneCodeStatus} from "../../network/manager/phonecodeRequest";

    Vue.prototype.$userEditToast = UserEditToast
    Vue.prototype.$partnerEditToast = PartnerEditToast
    Vue.prototype.$agentEditToast = AgentEditToast
    export default {
        name: "ManagerPhoneCode",
        mixins: [manager],
        components: {DateSelectorComp, ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        data() {
            return {
                startDate: '',
                endDate: '',
                pageNum: 1,
                pageSize: 20,
                pageTotal: 0,
                code: {},
                codeList: [],

            }
        },
        methods: {
            editPartner(id, mode) {
                this.$agentEditToast({'agentId': id, 'mode': mode})
            },
            editUser(userId, mode) {
                this.$userEditToast({'userId': userId, 'mode': mode})
            },
            getPhoneCodeList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                getPhoneCodeList(this.code, this.pageNum, this.pageSize).then(res => {
                    this.codeList = res.data.data
                    this.pageTotal = res.data.total
                    loadingInstance.close()
                })
            },
            updateChecked(item){
                item.checked = managerConst.CHECKED
                updatePhoneCodeStatus(item).then(res=>{
                    this.getPhoneCodeList()
                })

            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getPhoneCodeList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getPhoneCodeList();
            },
            setStartDate() {
                this.startDate = this.$moment(this.startDate).format('yyyy-MM-DD');
            },
            setEndDate() {
                this.endDate = this.$moment(this.endDate).format('yyyy-MM-DD')
            },
        },
        created() {
            this.getPhoneCodeList()
        },
    }
</script>

<style scoped>

</style>